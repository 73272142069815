import { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import "./checkout.css";
import Header from "../../components/Header";
import Address from "../../components/Address";
import axios from "axios";
import { toast } from "react-toastify";
import { Store } from "../../Store";

const Checkout = () => {
  const [value, setValue] = useState("1");
  const [err, setError] = useState("");
  const [addr, setAddr] = useState(true);
  const [data, setData] = useState({});
  const [country, setCountry] = useState("");
  const [lb, setLb] = useState(false);
  const { state, dispatch } = useContext(Store);
  const [ids, setIds] = useState([]);
  const {
    cart: { cartItems },
  } = state;

  useEffect(() => {
    cartItems.map((cart) => {
      setIds((prevIds) => [...prevIds, cart._id]);
    });
  }, [cartItems]);

  useEffect(() => {
    // Replace 'YOUR_API_KEY' with your actual API key from ipstack.com
    const apiKey = "53ef6df42347e9d19d6132a736f0781f";
    const ipstackUrl = `http://api.ipstack.com/check?access_key=${apiKey}`;

    axios
      .get(ipstackUrl)
      .then((response) => {
        const data = response.data;
        const userCountry = data.country_name;
        if (userCountry === "Lebanon") {
          localStorage.setItem("lbc", true);
          setLb(true);
        } else {
          localStorage.setItem("lbc", false);
          setLb(false);
        }
        setCountry(userCountry);
      })
      .catch((error) => {
        console.error("Error fetching user's country:", error);
      });
  }, []);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setData({
      ...data,
      [e.target.name]: value,
    });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const checkAddr = () => {
    const addr = localStorage.getItem("addr");
    setAddr(!!addr); // Using double negation to convert to a boolean
    if (addr) {
      setData(JSON.parse(addr));
    }
  };

  useEffect(() => {
    checkAddr();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !data.region ||
      !data.city ||
      !data.district ||
      !data.street ||
      !data.building ||
      !data.floor
    ) {
      setError("All Fields Are Required");
      return;
    }

    if (country.toLowerCase() !== data.region.toLowerCase()) {
      toast.error(
        "Your region location is different than the region you chose"
      );
      return;
    }

    if (
      country.toLowerCase() === "lebanon" &&
      data?.region?.toLowerCase() === "lebanon"
    ) {
      try {
        await axios.put(
          `https://api.hopesdolls.com/api/users/${
            JSON.parse(localStorage.getItem("user"))._id
          }`,
          {
            address: {
              region: data.region,
              city: data.city,
              district: data.district,
              street: data.street,
              building: data.building,
              floor: data.floor,
            },
          },
          {
            headers: {
              "ngrok-skip-browser-warning": "anyvalue",
            },
          }
        );

        await axios.post(
          "https://api.hopesdolls.com/api/orders",
          {
            client_id: JSON.parse(localStorage.getItem("user"))._id.toString(),
            product_id: ids,
            payment_type: "cash on delivery",
            quantity: 1,
            totalPrice: 2,
          },
          {
            headers: {
              "ngrok-skip-browser-warning": "anyvalue",
            },
          }
        );

        toast.success("Your Order Has Been placed Successfully!");
      } catch (err) {
        toast.error("Something wrong happened please contact us");
      }
    }
  };

  return (
    <>
      <Header />
      <div className="checkout-container">
        {err !== "" && <p>{err}</p>}
        <Box sx={{ width: "100%", typography: "body1" }}>
          <Address
            handleSubmit={handleSubmit}
            handleChange={handleInputChange}
            addr={addr}
            lb={lb}
          />
        </Box>
      </div>
    </>
  );
};

export default Checkout;
