import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Loader from "./components/Loader";
import Dashboard from "./Dashboard/Dashboard";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Dolls from "./pages/Dolls";
import Hoops from "./pages/Hoops";
import PsychologicalFacts from "./pages/PsychologicalFacts";
import FillDolls from "./pages/FillDolls";
import DollsHouse from "./pages/DollsHouse";
import { useEffect, useState } from "react";
import axios from "axios";
import UserLogin from "./pages/UserLogin";
import NotFound from "./pages/NotFound";
import Story from "./pages/Story";
import "./App.css";
import DollById from "./pages/DollById";
import CheckOut from "./pages/Checkout/Checkout.jsx";
import Cart from "./pages/Cart/Cart";
import Settings from "./pages/Settings/Settings";

function App() {
  const [categories, setCategories] = useState([]);
  const [user, setUser] = useState(false);

  // function getUserCountry(callback) {
  //   if ("geolocation" in navigator) {
  //     fetch(
  //       "http://api.ipstack.com?access_key=53ef6df42347e9d19d6132a736f0781f"
  //     )
  //       .then((response) => response.json())
  //       .then((data) => {
  //         const country = data.country;
  //         console.log("User's country:", country);
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching location:", error);
  //       });
  //   } else {
  //     console.log("Geolocation is not available in this browser.");
  //   }
  // }

  useEffect(() => {
    getcategories();
    checkUser();
  }, []);

  // getUserCountry(function (country) {
  //   console.log(country);
  //   if (country === "Lebanon") {
  //     localStorage.setItem("lbc", true);
  //   } else {
  //     localStorage.setItem("lbc", false);
  //   }
  // });

  const [country, setCountry] = useState(null);

  useEffect(() => {
    // Replace 'YOUR_API_KEY' with your actual API key from ipstack.com
    const apiKey = "53ef6df42347e9d19d6132a736f0781f";
    const ipstackUrl = `https://api.ipstack.com/check?access_key=${apiKey}`;

    axios
      .get(ipstackUrl)
      .then((response) => {
        const data = response.data;
        const userCountry = data.country_name;
        console.log(userCountry);
        if (userCountry === "Lebanon") {
          localStorage.setItem("lbc", true);
        } else {
          localStorage.setItem("lbc", false);
        }
        setCountry(userCountry);
      })
      .catch((error) => {
        console.error("Error fetching user's country:", error);
      });
  }, []);

  const getcategories = async () => {
    let res = await axios.get(`https://api.hopesdolls.com/api/categories`, {
      headers: {
        "ngrok-skip-browser-warning": "asda",
      },
    });
    try {
      setCategories(res.data);
    } catch (err) {
      console.log(err);
    }
  };
  const checkUser = () => {
    localStorage.getItem("user") !== null ? setUser(true) : setUser(false);
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route index path="/" element={<Home />} />
        {localStorage.getItem("token") !== null ? (
          <Route index path="/dashboard/*" element={<Dashboard />} />
        ) : (
          <Route path="*" element={<NotFound />} />
        )}
        <Route path="/adminlogin" element={<Login />} />
        <Route path="/login" element={<UserLogin />} />
        <Route path="/loading" element={<Loader />} />
        <Route path="/story" element={<Story />} />
        <Route path="/facts/:name" element={<PsychologicalFacts />} />
        <Route path="/filldoll/:name" element={<FillDolls />} />
        <Route path="/dollhouse/:name" element={<DollsHouse />} />
        <Route path="/product/:id" element={<DollById />} />
        <Route path="/checkout" element={user ? <CheckOut /> : <UserLogin />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="*" element={<NotFound />} />
        {categories.map((each) => {
          return each.name === "dolls" ? (
            <Route
              key={each._id}
              path="/dolls"
              element={<Dolls id={each._id} />}
            />
          ) : (
            <Route
              key={each._id}
              path="/hoops"
              element={<Hoops id={each._id} />}
            />
          );
        })}
        {user ? (
          <Route element={<Settings />} path="/settings" />
        ) : (
          <Route path="/settings" element={<NotFound />} />
        )}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
