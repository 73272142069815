import React, { useEffect, useState } from "react";
import "./Header.css";
import { Link } from "react-router-dom";
import logo from "../images/DollsLogo.png";
import PersonIcon from "@mui/icons-material/Person";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DropDown from "./DropDown";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
export default function Header() {
  const [user, setUser] = useState(false);
  const getUser = () => {
    localStorage.getItem("user") !== null ? setUser(true) : setUser(false);
  };
  useEffect(() => {
    getUser();
  }, []);
  return (
    <div>
      <div className="top-header">
        <div className="start-p">
          <a
            className="whatsapp-header-link"
            href="https://wa.me/96170297540"
            target="_blank"
            rel="noopener noreferrer"
          >
            <WhatsAppIcon />
            <span>WhatsApp</span>
          </a>
        </div>
        <div className="end-p">
          {user ? (
            <Link
              to="/settings"
              className="end-p"
              style={{ textDecoration: "none", color: "white" }}
            >
              <div className="account">
                <PersonIcon />
              </div>
              <div className="account-arrow">
                Settings
                <ArrowDropDownIcon />
              </div>{" "}
            </Link>
          ) : (
            <Link to="/login">Login</Link>
          )}
          <div className="ShoppingCartIcon-responsive">
            <Link to="/cart">
              <ShoppingCartOutlinedIcon />
              <div className="cart-icon" />
            </Link>
          </div>
          <DropDown />
          {/* <DropDown /> */}
        </div>
      </div>
      <div className="logo-img">
        <div className="logo_hopes">
          <img src={logo} alt="hopes"></img>
        </div>
        <div className="ShoppingCartIcon">
          <Link to="/cart" style={{display: 'grid'}}>
            <ShoppingCartOutlinedIcon />
            <div className="cart-icon">1</div>
          </Link>
        </div>
      </div>

      <div className="menu">
        <ul>
          <li>
            <Link className="links" to="/">
              Home
            </Link>
          </li>
          <li>
            <Link className="links" to="/story">
              Story
            </Link>
          </li>
          <li>
            <Link className="links" to="/dolls">
              Dolls
            </Link>
          </li>
          <li>
            <Link className="links" to="/hoops">
              Hoops
            </Link>
            {/* <Link className="links-hoops" to="/">
              Hoops<ArrowDropDownIcon></ArrowDropDownIcon>
            </Link> */}
          </li>
          <li>
            <Link className="links" to="/facts/PsychologicalFacts">
              Psychological Facts
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}
